<template>
	<div>

		<div class="bg bg3">
			<div class="mask"></div>
			<div class="text">
				<h1>关于我们</h1>
				<p>ABOUT US</p>
			</div>
		</div>
		<div class="flex flex-hw flex-sb about-box">
			<div class="about-item">
				<h2>1.16亿元</h2>
				<h4>实缴注册资金</h4>
				<p>深圳国资委、大型央企联合投资。主要股东有中国国新、深创投、建信资本、大地保险等大型国有投资机构</p>
			</div>
			<div class="about-item">
				<h2>T2B2C</h2>
				<h4>T2B2C核心业务</h4>
				<p>数字手段（5G网络、IOT平台、数据中台、IOC可视化平台等）赋能大型B端客户提升智能化水平与组织绩效</p>
			</div>
			<div class="about-item">
				<h2>700人+</h2>
				<h4>在职员工</h4>
				<p>核心团队由中兴、华为、腾讯资深人员构成</p>
			</div>
			<div class="about-item">
				<h2>22省市</h2>
				<h4>销售及运营中心</h4>
				<p>包含香港在内的22个省份舍友销售及运营团队</p>
			</div>
			<div class="about-item">
				<h2>100+43</h2>
				<h4>100项软著、43项专利发明</h4>
				<p>拥有5G网络、IOT平台、数据中台、IOC可视化平台、机器学习平台等核心技术</p>
			</div>
			<div class="about-item">
				<h2>3大场景赋能</h2>
				<h4>赋能场景</h4>
				<p>深度服务电信运营商、机场空管、工业企业、政府机构、园区、金融机构等，为客户进行营销-管理-生成赋能。</p>
			</div>
		</div>
		<div class="team-main">
			<h1>团队&荣誉</h1>
			<div class="flex flex-hw flex-sb team-box">
				<div class="flex flex-vc team-item">
					<div class="team-item-left">1</div>
					<div class="team-item-right">
						<h3>CMMI Maturity Level 5</h3>
						<p>CMMI Institute Partner （软件成熟度全球最高等级）</p>
					</div>
				</div>
				<div class="flex flex-vc team-item" style="background: rgba(25,137,251,0.1);">
					<div class="team-item-left" style="background-color: #1989fb;">2</div>
					<div class="team-item-right" style="color: #1989fb;">
						<h3>广东省产业大数据及人工智能工程技术中心</h3>
					</div>
				</div>
				<div class="flex flex-vc team-item" style="background: rgba(25,137,251,0.1);">
					<div class="team-item-left" style="background-color: #1989fb;">3</div>
					<div class="team-item-right" style="color: #1989fb;">
						<h3>国家规划布局重点软件企业</h3>
					</div>
				</div>
				<div class="flex flex-vc team-item">
					<div class="team-item-left">4</div>
					<div class="team-item-right">
						<h3>华为联合创新合作伙伴</h3>
					</div>
				</div>
				<div class="flex flex-vc team-item">
					<div class="team-item-left">5</div>
					<div class="team-item-right">
						<h3>广东省工信厅推荐工业互联网服务企业</h3>
					</div>
				</div>
				<div class="flex flex-vc team-item" style="background: rgba(25,137,251,0.1);">
					<div class="team-item-left" style="background-color: #1989fb;">6</div>
					<div class="team-item-right" style="color: #1989fb;">
						<h3>深圳市工信局5G应用推广企业</h3>
					</div>
				</div>
				<div class="flex flex-vc team-item" style="background: rgba(25,137,251,0.1);">
					<div class="team-item-left" style="background-color: #1989fb;">7</div>
					<div class="team-item-right" style="color: #1989fb;">
						<h3>深圳市2021年技术攻关参与企业</h3>
					</div>
				</div>
				<div class="flex flex-vc team-item">
					<div class="team-item-left">8</div>
					<div class="team-item-right">
						<h3>深圳市大数据研究与应用协会常务副会长单位</h3>
					</div>
				</div>

			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	export default {

		metaInfo: {
			title: '微品能源-关于我们',
			meta: [{
					name: 'keywords',
					content: '微品能源,智慧用电,'
				},
				{
					name: 'description',
					content: '微品能源官方网站'
				}
			]
		}

	}
</script>

<style scoped>
	.about-box {
		max-width: 1200px;
		margin: 50px auto 0 auto;


	}

	.about-box .about-item {
		width: 320px;
		padding: 20px;
		background-color: #f7f8f9;
		margin-bottom: 50px;
		border-radius: 4px;

	}

	.about-item h4 {
		color: #f36f20;
	}

	.about-item p {
		text-align: justify;
		line-height: 30px;
	}

	.bg {
		background-size: cover;
		width: 100%;
		height: 500px;
		position: relative;

	}

	.bg .mask {
		background: rgba(0, 0, 0, 0.2);
		position: absolute;
		width: 500px;
		height: 400px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.bg .text {
		font-size: 40px;
		color: #ffffff;
		font-weight: bold;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.bg3 {
		background-image: url('../assets/img/bannerin.jpg');
	}

	.team-main {
		width: 1200px;
		margin: 0 auto;
	}

	.team-main h1 {
		margin-bottom: 50px;
	}

	.team-box {}

	.team-item {
		background: rgba(223, 83, 27, 0.1);
		width: 550px;
		height: 80px;
		margin-bottom: 50px;
		border-radius: 50px;
		overflow: hidden;
	}

	.team-item-left {
		background: #DF531B;
		width: 80px;
		height: 80px;
		line-height: 80px;
		color: #ffffff;
		text-align: center;
		font-size: 50px;
	}

	.team-item-right {
		padding: 0 15px;
		color: #DF531B;
		text-align: left;
	}

	.team-item-right h3 {
		margin: 0;
		font-size: 22px;
	}

	.team-item-right p {
		margin: 5px 0 0 0;

	}
</style>
